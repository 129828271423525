import { render, staticRenderFns } from "./Msgchat.vue?vue&type=template&id=5e9f4c88&scoped=true&"
import script from "./Msgchat.vue?vue&type=script&lang=ts&"
export * from "./Msgchat.vue?vue&type=script&lang=ts&"
import style0 from "./Msgchat.vue?vue&type=style&index=0&id=5e9f4c88&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e9f4c88",
  null
  
)

export default component.exports