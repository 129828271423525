































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { JWTModule, MessagingModule } from "@/store/modules";
import { Chat } from "@/store/models/chat";
import helpers from "@/utils/helpers";
import InfiniteLoading from "vue-infinite-loading";
import SocketHandler from "@/utils/socketHandler";
import { WaitUntilRefreshed } from "@/utils/jwt";

@Component({
  components: { InfiniteLoading },
})
export default class UserChat extends Vue {
  $refs!: {
    chatMessages: InstanceType<typeof HTMLElement>;
    infiniteLoading: InstanceType<typeof InfiniteLoading>;
  };
  isMini: boolean = false;
  sendMessage: Chat = {} as Chat;
  isSendingInProgress: boolean = false;
  userUid: string | undefined = undefined;
  username: string = "";

  socket: WebSocket | undefined = undefined;

  chatFilter: {
    totalPage: number;
    userId: number;
    pageSize: number | null;
  } = {} as {
    totalPage: number;
    userId: number;
    pageSize: number | null;
  };

  get isMessageBoxActive() {
    this.scrollToBottom();
    return MessagingModule.isMessageBoxActive;
  }

  get userChatPaginatedMessages() {
    return MessagingModule.userChatPaginatedMessages;
  }

  get userChatMessages() {
    return MessagingModule.userChatMessagesList;
  }

  get updateScrollToBottom() {
    return MessagingModule.scrollToBottom;
  }

  get isLoggedIn() {
    return JWTModule.isLoggedIn;
  }

  @Watch("updateScrollToBottom")
  onUpdateScrollToBottom() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.$nextTick(() => {
      const chatMessages = document.getElementsByClassName(
        "chat-box__message__wrapper"
      );
      if (chatMessages.length > 0) {
        const lastChatMessage = chatMessages[chatMessages.length - 1];
        lastChatMessage.scrollIntoView(false);
      }
    });
  }

  close() {
    this.isMini = false;
    MessagingModule.setIsMessageBoxActive(false);
  }

  onSendMessage() {
    if (
      this.sendMessage &&
      this.sendMessage.message &&
      this.sendMessage.message != "" &&
      this.userUid
    ) {
      this.scrollToBottom();
      const message = {
        message: this.sendMessage.message,
        adminId: null,
        publicId: this.userUid,
      };
      const socketMessage = {
        data: message,
        type: "message",
      };
      if (this.socket) {
        this.isSendingInProgress = true;
        this.socket.send(JSON.stringify(socketMessage));
        this.isSendingInProgress = false;
        this.sendMessage = {} as Chat;
      } else {
        this.connectAndSend(socketMessage);
      }
    }
  }

  infiniteHandler($state: any) {
    if (
      this.chatFilter.pageSize &&
      this.chatFilter.pageSize < this.chatFilter.totalPage
    ) {
      this.chatFilter.pageSize++;
    } else {
      $state.loaded();
      $state.complete();
      return;
    }

    MessagingModule.getUserChat(this.chatFilter)
      .then(async () => {
        // Sleep to prevent infinite loading after api hit
        // Messages are loaded from selectedUserMessageList getter
        // await new Promise((resolve) => setTimeout(resolve, 1000));
        $state.loaded();
      })
      .catch(() => {
        $state.complete();
      });
  }

  momentDate(date: any) {
    return helpers.toMomentDate(date);
  }

  @Watch("isLoggedIn")
  onLoginChange() {
    if (this.socket) {
      this.socket.close();
    }
    this.getFirstChatPage();
  }

  connectToChat() {
    let token = helpers.getAccessToken();
    if (
      token &&
      helpers.hasRoles([
        "public",
        "driver",
        "gps_service_provider",
        "vehicle_owner",
      ])
    ) {
      this.socket = SocketHandler.createConnection(`chat/?token=${token}`);
    }
  }

  connectAndSend(socketMessage: { data: any; type: string }) {
    this.connectToChat();
    if (this.socket) {
      this.isSendingInProgress = true;
      this.socket.send(JSON.stringify(socketMessage));
      this.isSendingInProgress = false;
      this.sendMessage = {} as Chat;
    }
  }

  async getFirstChatPage() {
    await WaitUntilRefreshed();
    this.userUid = helpers.getUserUId();
    this.username = helpers.getUserName();
    if (this.userUid) {
      this.connectToChat();
      this.chatFilter.pageSize = 1;
      await MessagingModule.getUserChat(this.chatFilter);
      this.chatFilter.totalPage = this.userChatPaginatedMessages.totalPages;

      // Reset infinite loader after selecting new user's message
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.reset();
      }
    }
  }

  created() {
    this.getFirstChatPage();
  }
}
